import React from "react";
import { CardDeck, Card, CardBody, Row, Button } from "reactstrap";
import local from "../../localization/strings";
import SiteThumbnail from "./SiteThumbnail";

const DistributorSiteImages = ({ sites, accessSite, when }) => {
	// Darca Pro feature - border around box:
	//  - Green if all site checks ok
	//  - Orange if anything needs attention (outside threshold levels, battery levels low etc)
	//  - Red if urgent attention needed (channel in alarm, battery level below 5% etc)
	//  Icons with number next to them to show issue - e.g. bell icon with 2 next to it to show 2 channels in alarm
	return (
		<Card>
			<CardBody className="fs--1 font-weight-normal p-3 w-100">
				{sites && (
					<CardDeck>
						{sites.map((site, i) => (
							<Card key={`${i}_${when}`} index={i} className="dash-border col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 py-3 px-4 mb-2 distributor-card">
								<Row className="distributor-site-image">
									<SiteThumbnail displayName={site.displayName} id={site.id} when={when} />
								</Row>
								<Row className="mt-2 distributor-site-name">
									<h5 className="mx-auto text-center">{site.displayName}</h5>
								</Row>
								<Row>
									{/* Button click to change currently selected site & navigate to dashboard */}
									<Button color="primary" className="mx-auto mt-2 mb-0" onClick={() => accessSite(site.id)}>
										{local.TF_View_site}
									</Button>
								</Row>
							</Card>
						))}
					</CardDeck>
				)}
			</CardBody>
		</Card>
	);
};

export default DistributorSiteImages;
