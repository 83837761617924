import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useCallback } from "react";
import { forwardRef } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { UserPreferencesContext } from "../../context/userPreferences";
import local from "../../localization/strings";
import ColourPickerButton from "../common/ColourPickerButton";

const DataSeriesOptions = forwardRef(({ locationId, options, onClose, zoneId, groupId, chartIndex, buildTitle, updateTabName }, ref) => {
	const [dataSeries, setDataSeries] = useState();
	const [index, setIndex] = useState(0);
	const [showToggle, setShowToggle] = useState(false);
	const [seriesCount, setSeriesCount] = useState(0);
	const { saveTierPreference } = useContext(UserPreferencesContext);

	useEffect(() => {
		if (locationId) {
			const allDataSeries = ref.get("data");
			const foundIndex = allDataSeries.findIndex((x) => x?.options?.locationId === locationId);

			if (foundIndex === -1) {
				onClose();
				return;
			}

			const found = allDataSeries[foundIndex];
			setIndex(foundIndex);
			setDataSeries(found);

			let cSeries = 0;
			allDataSeries.forEach((ds) => {
				if (ds.options.locationId) {
					++cSeries;
				}
			});
			setSeriesCount(cSeries);

			if (found.axisXType === "secondary") {
				setShowToggle(false);
			} else if (!found.visible) {
				setShowToggle(true);
			} else {
				let visibleCount = 0;
				allDataSeries.forEach((ds) => {
					if (ds.visible && ds.options.locationId) {
						++visibleCount;
					}
				});

				setShowToggle(visibleCount > 1);
			}
		} else {
			setDataSeries(undefined);
			setShowToggle(false);
		}
	}, [locationId, ref, onClose]);

	const updateTitleAndTab = useCallback(() => {
		const tabName = buildTitle(ref.data.map((x) => x.options), false, true);
		const chartTitle = buildTitle(ref.data.map((x) => x.options), true, false);
		updateTabName(groupId, chartIndex, tabName);
		ref.title.set("text", chartTitle);
	}, [buildTitle, updateTabName, groupId, chartIndex, ref]);

	const onToggle = useCallback(
		(show) => {
			ref.data[index].set("visible", show, true);

			saveTierPreference(
				"Chart",
				zoneId,
				groupId,
				chartIndex,
				"Chart_Visibility",
				JSON.stringify(
					ref
						.get("data")
						.filter((x) => x.options.locationId)
						.map((x) => `${x.options.locationId}:${x.visible}`),
				),
			);

			updateTitleAndTab();
			onClose();
		},
		[ref, index, onClose, zoneId, groupId, chartIndex, saveTierPreference, updateTitleAndTab],
	);

	const onColourChange = useCallback(
		(newColour) => {
			if (ref.data[index].get("legendMarkerBorderColor")) {
				ref.data[index].set("legendMarkerBorderColor", newColour, false);
			}
			ref.data[index].set("legendMarkerColor", newColour, false);
			ref.data[index].set("lineColor", newColour, false);
			ref.data[index].set("color", newColour, true);

			saveTierPreference(
				"Chart",
				zoneId,
				groupId,
				chartIndex,
				"Chart_Colour",
				JSON.stringify(
					ref
						.get("data")
						.filter((x) => x.options.locationId)
						.map((x) => `${x.options.locationId}:${x.color}`),
				),
			);

			onClose();
		},
		[ref, index, onClose, zoneId, groupId, chartIndex, saveTierPreference],
	);

	const move = useCallback(
		(to) => {
			let moveTo = 0;

			switch (to) {
				case "First":
					moveTo = 0;
					break;
				case "Left":
					moveTo = index - 1;
					break;
				case "Right":
					moveTo = index + 2;
					break;
				case "Last":
					moveTo = seriesCount;
					break;
				default:
					return;
			}
			const newSeries = JSON.parse(JSON.stringify(options.data[index]));

			newSeries.dataPoints.forEach((point) => {
				point.x = new Date(point.x);
			});

			ref.addTo("data", newSeries, moveTo, false);
			ref.data[index].remove();
			ref.render();

			saveTierPreference(
				"Chart",
				zoneId,
				groupId,
				chartIndex,
				"Chart_Order",
				JSON.stringify(
					ref
						.get("data")
						.filter((x) => x.options.locationId)
						.map((x) => x.options.locationId),
				),
			);

			updateTitleAndTab();
			onClose();
		},
		[ref, options, onClose, index, seriesCount, zoneId, groupId, chartIndex, saveTierPreference, updateTitleAndTab],
	);

	if (!locationId || !dataSeries) {
		return null;
	}

	return (
		<Modal isOpen={!!locationId} toggle={onClose} centered backdrop="static">
			<ModalHeader>{dataSeries?.name}</ModalHeader>
			<ModalBody>
				<ColourPickerButton value={dataSeries?.color} onChange={onColourChange} />
				{showToggle && dataSeries.visible && (
					<Button size="sm" color="primary" onClick={() => onToggle(false)} className="mr-2">
						{local.TS_Hide}
					</Button>
				)}
				{showToggle && !dataSeries.visible && (
					<Button size="sm" color="primary" onClick={() => onToggle(true)} className="mr-2">
						{local.TS_Show}
					</Button>
				)}
				{seriesCount > 1 && (
					<>
						{index > 0 && (
							<>
								<Button size="sm" color="primary" onClick={() => move("First")} className="mr-2" title={local.TF_Layers_SendToBack}>
									<FontAwesomeIcon icon="angle-double-left" />
								</Button>
								<Button size="sm" color="primary" onClick={() => move("Left")} className="mr-2" title={local.TF_Layers_SendBackward}>
									<FontAwesomeIcon icon="angle-left" />
								</Button>
							</>
						)}
						{index < seriesCount - 1 && (
							<>
								<Button size="sm" color="primary" onClick={() => move("Right")} className="mr-2" title={local.TF_Layers_BringForward}>
									<FontAwesomeIcon icon="angle-right" />
								</Button>
								<Button size="sm" color="primary" onClick={() => move("Last")} className="mr-2" title={local.TF_Layers_BringToFront}>
									<FontAwesomeIcon icon="angle-double-right" />
								</Button>
							</>
						)}
					</>
				)}
				<div className="mt-2 text-right">
					<Button size="sm" color="secondary" onClick={() => onClose()} className="">
						{local.TS_Close}
					</Button>
				</div>
			</ModalBody>
		</Modal>
	);
});

export default DataSeriesOptions;
