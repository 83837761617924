import React, { useState, useEffect } from "react";
import { Card, Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import ChartReportFilter from "./ChartReportFilter";
import ChartReportToolbar from "./ChartReportToolbar";
import ChartReportPage from "./ChartReportPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import local from "../../../localization/strings";
import PageTitle from "../../common/PageTitle";
import { userPreferencesGetList, userPreferenceSave } from "../../../api/userPreferences";
import Loader from "../../common/Loader";
import { zoneChartList } from "../../../api/chart";
import EmailModal from "../../common/EmailModal";

const buttonDefaultClasses = "btn-shadow btn-sm mb-sm-2 py-2 px-2 px-sm-3 py-sm-1";

const ChartReport = () => {
	const [loading, setLoading] = useState(true);
	const [reportRunning, setReportRunning] = useState(false);
	const [filterData, setFilterData] = useState({});
	const [showZoneSelect, setShowZoneSelect] = useState(true);
	const [zoneCharts, setZoneCharts] = useState([]);
	const [showEmail, setShowEmail] = useState(false);

	const LoadZoneCharts = async (zoneId) => {
		setReportRunning(true);
		var result = await zoneChartList(zoneId.replace("Z:", ""));
		setZoneCharts(result);
	};

	useEffect(() => {
		const defaultMissing = (data) => {
			if (!data.chartsPerPage) {
				data.chartsPerPage = 1;
			}

			if (!data.period) {
				data.period = "Day";
			}

			if (typeof data.landscape === "undefined") {
				data.landscape = true;
			}

			if (!data.showList) {
				data.showList = [];
			}
			return data;
		};

		const LoadData = async () => {
			const prefs = await userPreferencesGetList();

			var match = prefs.find((x) => x.preferenceKey === "ChartReport_Filter");

			if (match) {
				var data = JSON.parse(match.preferenceValue);
				setFilterData(defaultMissing(data));
			} else {
				setFilterData(defaultMissing({}));
			}

			setLoading(false);
		};

		LoadData();
	}, []);

	const updatePreferences = async (prefs) => {
		setReportRunning(true);

		const newData = { ...filterData, ...prefs };

		setFilterData(newData);
		await userPreferenceSave("ChartReport_Filter", JSON.stringify(newData));
	};

	const updateZoneId = async (zoneId) => {
		await updatePreferences({ zoneId, orderList: null, showList: null });

		await LoadZoneCharts(zoneId);

		setShowZoneSelect(false);
	};

	const toggleEmailModal = () => {
		setShowEmail(!showEmail);
	};

	return (
		<>
			<Row>
				<Col className="col-10">
					<PageTitle title={local.TS_ChartReport} />
				</Col>
				{!loading && !showZoneSelect && (
					<Col className="float-right mt-3 mb-n2 col-2">
						<UncontrolledDropdown size="sm" direction="left" className="allow-overflow float-right">
							<DropdownToggle caret color="info" transform="shrink-3" className={buttonDefaultClasses} disabled={reportRunning}>
								<FontAwesomeIcon icon="share-alt" />
								<span className="d-none d-md-inline-block ml-2">{local.TS_Options}</span>
							</DropdownToggle>
							<DropdownMenu className="menu-border-blue dropdown-menu">
								{/* v0.9 */}
								<DropdownItem disabled>
									<FontAwesomeIcon icon="download" /> {local.TS_Download}
								</DropdownItem>
								<DropdownItem disabled>
									<FontAwesomeIcon icon="print" /> {local.TS_Print}
								</DropdownItem>
								<DropdownItem disabled>
									<FontAwesomeIcon icon="copy" /> {local.TS_Copy}
								</DropdownItem>
								<DropdownItem disabled>
									<FontAwesomeIcon icon="envelope-square" /> {local.TS_Email}
								</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
					</Col>
				)}
			</Row>
			<Card className="p-3 mt-2" id="chart-report-div">
				{loading ? (
					<Loader />
				) : (
					<Row className="text-center">
						<Col className="col-12">
							{showZoneSelect ? (
								<ChartReportFilter selected={updateZoneId} filterData={filterData} />
							) : (
								<>
									<ChartReportToolbar filter={updatePreferences} filterData={filterData} showSelectZone={setShowZoneSelect} zoneCharts={zoneCharts} setReportRunning={setReportRunning} />
									<ChartReportPage filterData={filterData} zoneCharts={zoneCharts} setReportRunning={setReportRunning} />
								</>
							)}
						</Col>
					</Row>
				)}
			</Card>
			<EmailModal show={showEmail} toggleEmailModal={toggleEmailModal} />
		</>
	);
};

export default ChartReport;
