import React, { useEffect, useState, useCallback } from "react";
import jsPDF from "jspdf";
import PDFObject from "pdfobject";
import CanvasJSReact from "../../../lib/canvasjs.react";
import { chartData } from "../../../api/chart";
import Loader from "../../common/Loader";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ChartReportPdf = ({ filterData, previewHeight, setReportRunning }) => {
	const [loading, setLoading] = useState(true);
	const [optionSets, setOptionSets] = useState([]);
	const top = 8;
	const left = 0;
	const width = (landscape) => (landscape ? 297 : 210);
	const height = (landscape) => (landscape ? 210 : 297);
	const chartWidth = (filterData.landscape ? 1090 : 770) / window.devicePixelRatio;

	const makePdf = useCallback(() => {
		const writeHeaderOrFooter = (options, y) => {
			if (options) {
				doc.setFont(options.font);
				if (options.fontStyle) {
					doc.setFontStyle(options.fontStyle);
				} else {
					doc.setFontStyle("normal");
				}
				doc.setFontSize(options.fontSize);
				switch (options.align) {
					case "left":
						doc.text(options.text, left + 5, y);
						break;
					default:
					case "center":
						doc.text(options.text, width(filterData.landscape) / 2, y, null, null, "center");
						break;
					case "right":
						doc.text(options.text, width(filterData.landscape) - 5, y, null, null, "right");
						break;
				}
			}
		};

		const writeHeaderAndFooter = () => {
			writeHeaderOrFooter(filterData.header, top);
			writeHeaderOrFooter(filterData.footer, height(filterData.landscape) - 5);
		};

		var doc = new jsPDF(filterData.landscape ? "landscape" : "portrait");

		const yOffset = (filterData.landscape ? 180 : 280) / filterData.chartsPerPage;
		const containers = document.getElementsByClassName("canvasjs-chart-container");

		let y = 10;
		let chartNumber = 0;
		containers.forEach((container) => {
			if (chartNumber === filterData.chartsPerPage) {
				writeHeaderAndFooter();
				doc.addPage();
				y = 10;
				chartNumber = 0;
			}

			var canvas = container.firstChild;
			if (canvas) {
				var data = canvas.toDataURL("image/jpeg", 1);
				doc.addImage(data, "JPEG", 5, y);
				y += yOffset;
			}
			chartNumber += 1;
		});

		writeHeaderAndFooter();
		return doc;
	}, [filterData]);

	const LoadData = useCallback(async () => {
		if (!filterData.showList || filterData.showList.length === 0) {
			return;
		}

		setLoading(true);
		let data = [];

		for (var i in filterData.orderList) {
			if (filterData.showList.includes(filterData.orderList[i])) {
				const parts = filterData.orderList[i].split(":");

				var result = await chartData(parts[0], parts[1], filterData.period, 0, (filterData.overlayIds || []).join(), null, null, filterData.from, filterData.to, "ChartReport");

				if (result.success) {
					result.data.data.forEach((element) => {
						element.lineThickness = 1;
						element.dataPoints.forEach((point) => {
							point.x = new Date(point.x);
						});
					});

					if (result.data.axisY) {
						result.data.axisY.stripLines.forEach((stripLine) => {
							stripLine.opacity = stripLine.opacityOriginal;
						});
					}

					if (result.data.axisY2) {
						result.data.axisY2.stripLines.forEach((stripLine) => {
							stripLine.opacity = stripLine.opacityOriginal;
						});
					}

					if (result.data.axisX) {
						result.data.axisX.crosshair.snapToDataPoint = true;
						result.data.axisX.labelFontSize = 12;

						if (result.data.axisX.minimum) {
							result.data.axisX.minimum = new Date(result.data.axisX.minimum);
						}

						if (result.data.axisX.maximum) {
							result.data.axisX.maximum = new Date(result.data.axisX.maximum);
						}
					}
					if (result.data.axisX2) {
						result.data.axisX2.crosshair.snapToDataPoint = true;
						result.data.axisX2.labelFontSize = 12;
					}
					if (result.data.axisY) {
						result.data.axisY.crosshair.snapToDataPoint = true;
					}
					if (result.data.axisY2) {
						result.data.axisY2.crosshair.snapToDataPoint = true;
						result.data.axisY2.gridDashType = "dash";
					}

					data.push(result.data);
				}
			}
		}

		setOptionSets(data);

		setTimeout(() => {
			var options = {
				pdfOpenParams: {
					view: "FitH",
				},
			};

			PDFObject.embed(makePdf(false).output("bloburl"), "#pdfPreview", options);

			setLoading(false);
			setReportRunning(false);
		}, 500);
	}, [makePdf, filterData, setReportRunning]);

	useEffect(() => {
		LoadData();
	}, [LoadData]);

	const chartHeight = () => {
		return (filterData.landscape ? 660 : 1000) / filterData.chartsPerPage / window.devicePixelRatio;
	};

	return (
		<>
			{loading && <Loader />}
			<div id="pdfPreview" style={{ width: "100%", minHeight: "400px", height: previewHeight, display: loading ? "none" : "block" }} />

			<div style={{ position: "absolute", top: -1000000, left: -1000000, zIndex: -1 }}>
				{optionSets.map((item, index) => (
					<CanvasJSChart key={`${index}_${filterData.chartsPerPage}_${window.devicePixelRatio}_${filterData.landscape}`} options={item} containerProps={{ height: chartHeight(), width: chartWidth }} />
				))}
			</div>
		</>
	);
};

export default ChartReportPdf;
