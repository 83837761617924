import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, Row, Col, Button, Alert } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import local from "../../localization/strings";
import { userGet, userCreate, userUpdate } from "../../api/users";
import { roles } from "../../config";

const DistributorEdit = ({ cancelEdit, id }) => {
	const [data, setData] = useState(undefined);
	const [error, setError] = useState(undefined);

	useEffect(() => {
		const LoadUser = async () => {
			setData(await userGet(id));
		};

		if (id) {
			LoadUser();
		} else {
			setData({});
		}
	}, [id]);

	async function save(_e, values) {
		if (id) {
			const resultUpdate = await userUpdate(values.email, values.displayName, roles.Distributor, values.companyName);

			switch (resultUpdate) {
				case "LASTADMIN":
					setError(local.TS_LastAdmin);
					return;
				default:
					setError(undefined);
					toast.success(local.TF_Distributor_successfully_updated);
					cancelEdit();
					return;
			}
		} else {
			const resultCreate = await userCreate(values.email, values.displayName, roles.Distributor, values.companyName);

			switch (resultCreate) {
				case "DUP":
					setError(local.TS_Duplicate_User);
					return;
				case "UPGRADE": 
					setError(local.TS_Max_Lite_Users);
					return;
				default:
					setError(undefined);
					toast.success(local.TF_Distributor_successfully_added);
					cancelEdit();
					return;
			}
		}
	}

	return (
		<Card className="p-3">
			<Row>
				<Col className="text-left">
					<h5 className="mb-2">{id ? local.TF_Editing_Distributor : local.TF_Adding_Distributor}</h5>
				</Col>
			</Row>

			{data && (
				<AvForm model={data} onValidSubmit={async (e, values) => await save(e, values)}>
					<AvField name="displayName" label={local.TS_Name} validate={{ required: { value: true, errorMessage: local.TS_Required } }} />
					<AvField name="companyName" label={local.TF_CompanyName} validate={{ required: { value: true, errorMessage: local.TS_Required } }} />
					<AvField name="email" label={local.TS_EmailAddress} disabled={id !== undefined} validate={{ required: { value: true, errorMessage: local.TS_Required } }} />
					{error && <Alert color="danger">{error}</Alert>}
					<Row>
						<Col>
							<Button color="primary" className="mt-2 float-left">
								{local.TS_Save_Changes}
							</Button>
							<Button color="secondary" className="mt-2 ml-2 float-left" onClick={cancelEdit}>
								{local.TS_Cancel}
							</Button>
						</Col>
					</Row>
				</AvForm>
			)}
		</Card>
	);
};

export default DistributorEdit;
