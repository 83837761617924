import React, { useState } from "react";
import AssignSitesList from "./AssignSitesList";
import DistributorList from "./DistributorList";
import DistributorEdit from "./DistributorEdit";

const DistributorTab = () => {
	const [assignSites, setAssignSites] = useState(false);
	const [assignSitesToDistributor, setAssignSitesToDistributor] = useState(undefined);
    const [edit, setEdit] = useState(false);
    const [editId, setEditId] = useState(undefined);

    const cancelEdit = () => {
        setEdit(false);
        setEditId(undefined);
    }

    const editDistributor = (id) => {
        setEditId(id);
        setEdit(true);
    }

	const cancelAssignSitesHandle = () => {
		setAssignSitesToDistributor(undefined);
		setAssignSites(false);
	}

	const assignSitesHandle = (distributor) => {
		setAssignSitesToDistributor(distributor);
		setAssignSites(true);
	};

    return (
        <>
			{ assignSites
				?
				<AssignSitesList cancelAssignSites={cancelAssignSitesHandle} distributor={assignSitesToDistributor} />
				:
				<>
					{ edit 
					?
					<DistributorEdit id={editId} cancelEdit={cancelEdit} />
					:
					<DistributorList assignSites={assignSitesHandle} edit={editDistributor} />
					}
				</>
			}
        </>
    );
};

export default DistributorTab;
