import React, { useState, useEffect } from "react";
import { Label, Row, Col, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import local from "../../../localization/strings";
import ChartReportHeaderFooter from "./ChartReportHeaderFooter";
import ChartReportOverlay from "./ChartReportOverlay";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { logEvent } from "../../../helpers/ga";
import { isFeatureEnabled, features } from "../../../config";
import ChartReportPeriod from "./ChartReportPeriod";

const buttonDefaultClasses = "btn-shadow btn-sm mb-sm-2 py-2 px-3 px-md-2 py-md-1 mr-2";

const ChartReportToolbar = ({ filter, filterData, showSelectZone, zoneCharts, emailReports }) => {
	const [landscape, setLandscape] = useState(true);
	const [showPeriod, setShowPeriod] = useState(false);
	const [from, setFrom] = useState(undefined);
	const [to, setTo] = useState(undefined);
	const [showHeaderFooter, setShowHeaderFooter] = useState(false);
	const [chartsPerPage, setChartsPerPage] = useState(1);
	const [period, setPeriod] = useState("Day");
	const [orderList, setOrderList] = useState([]);
	const [showList, setShowList] = useState([]);
	const [showOverlay, setShowOverlay] = useState(false);
	const [overlayIds, setOverlayIds] = useState([]);

	useEffect(() => {
		if (filterData.orderList) {
			setOrderList(filterData.orderList);
		} else {
			setOrderList(
				zoneCharts.map((item) => {
					return `${item.groupId}:${item.graphIndex}`;
				}),
			);
		}

		if (filterData.showList) {
			setShowList(filterData.showList);
		} else {
			setShowList([]);
		}

		setOverlayIds(filterData.overlayIds || []);

		// Special case here as bool
		if (typeof filterData.landscape === "undefined") {
			setLandscape(true);
		} else {
			setLandscape(filterData.landscape);
		}
		setChartsPerPage(filterData.chartsPerPage || 1);
		setPeriod(filterData.period || "Day");
	}, [filterData, zoneCharts]);

	const toggleShowOverlay = () => {
		logEvent("Chart Report", "Overlay Show");

		setShowOverlay(!showOverlay);
	};

	const updateOverlay = (locationIds) => {
		logEvent("Chart Report", "Overlay Update");

		setOverlayIds(locationIds);

		const newFilter = { ...filter, overlayIds: locationIds };
		if (filter) {
			filter(newFilter);
		}

		setShowOverlay(false);
	};

	const toggleShowHeaderFooter = () => {
		logEvent("Chart Report", "Header/Footer Show");

		setShowHeaderFooter(!showHeaderFooter);
	};

	const setCustomPeriod = (from, to) => {
		logEvent("Chart Report", "Set Custom Period");
		setFrom(from);
		setTo(to);
		setPeriod("Custom");

		if (filter) {
			filter({ ...filter, period: "Custom", from, to });
		}

		setShowPeriod(false);
	};

	const clearCustomPeriod = () => {
		logEvent("Chart Report", "Clear Custom Period");
		setFrom(undefined);
		setTo(undefined);
		setPeriod("Week");

		if (filter) {
			filter({ ...filter, period: "Week", from: undefined, to: undefined });
		}

		setShowPeriod(false);
	};

	const updateHeaderFooter = (data) => {
		logEvent("Chart Report", "Header/Footer Update");

		const newFilter = { ...filter, ...data };

		if (filter) {
			filter(newFilter);
		}

		setShowHeaderFooter(false);
	};

	const toggleLandscape = () => {
		const newValue = !landscape;

		logEvent("Chart Report", newValue ? "Landscape" : "Portrait");

		setLandscape(newValue);

		if (filter) {
			filter({ ...filter, landscape: newValue });
		}
	};

	const updateChartsPerPage = (number) => {
		logEvent("Chart Report", "Charts Per Page", `${number}`);

		setChartsPerPage(number);

		if (filter) {
			filter({ ...filter, chartsPerPage: number });
		}
	};

	const updatePeriod = (newPeriod) => {
		logEvent("Chart Report", "Period", `${newPeriod}`);

		setFrom(undefined);
		setTo(undefined);
		setPeriod(newPeriod);

		if (filter) {
			filter({ ...filter, period: newPeriod, from: undefined, to: undefined });
		}
	};

	const checkClicked = (value) => {
		let newList = showList;

		if (showList.includes(value)) {
			logEvent("Chart Report", "Remove Chart", `${value}`);

			newList = newList.filter((x) => x !== value);
		} else {
			logEvent("Chart Report", "Add Chart", `${value}`);

			newList.push(value);
		}

		setShowList(newList);

		if (filter) {
			filter({ ...filter, showList: newList });
		}
	};

	const onSortEnd = ({ oldIndex, newIndex }) => {
		logEvent("Chart Report", "Sort Charts", `${oldIndex} -> ${newIndex}`);

		const newOrder = arrayMove(orderList, oldIndex, newIndex);
		setOrderList(newOrder);

		if (filter) {
			filter({ ...filter, orderList: newOrder });
		}
	};

	const graphDescription = (value) => {
		var chart = zoneCharts.find((x) => `${x.groupId}:${x.graphIndex}` === value);

		if (chart) {
			return chart.groupName;
		}
		return "???";
	};

	const SortableItem = sortableElement(({ value }) => (
		<Row>
			<Col className="drag-drop-box-chart-report mb-2 mx-3 p-1">
				<Label check className="w-100">
					<input type="checkbox" className="mr-2" defaultChecked={showList.includes(value)} onClick={() => checkClicked(value)} />
					{graphDescription(value)}
					<FontAwesomeIcon icon="grip-lines" className="float-right mr-2 mt-1" />
				</Label>
			</Col>
		</Row>
	));

	const SortableContainer = sortableContainer(({ children }) => {
		return <div>{children}</div>;
	});
	return (
		<>
			<ChartReportPeriod show={showPeriod} set={setCustomPeriod} clear={clearCustomPeriod} options={{ from, to }} />
			<ChartReportHeaderFooter filterData={filterData} show={showHeaderFooter} close={toggleShowHeaderFooter} update={updateHeaderFooter} />
			{isFeatureEnabled(features.ChartReportOverlays) && <ChartReportOverlay onSave={(locationIds) => updateOverlay(locationIds)} overlays={overlayIds} filterData={filterData} showOverlay={showOverlay} close={toggleShowOverlay} />}
			<Row id="toolbar-row" className="justify-content-center p-2">
				<Button
					outline
					className={`dark-button ${buttonDefaultClasses}`}
					color="dark"
					transform="shrink-3"
					onClick={() => {
						logEvent("Chart Report", "Select Zone");
						showSelectZone(true);
					}}
				>
					<FontAwesomeIcon icon="filter" />
					<span className="d-none d-md-inline-block ml-2">{local.TF_ZoneCharts_SelectZone}</span>
				</Button>

				{/* Used to select a date range for all charts or this chart only */}
				<UncontrolledDropdown size="sm">
					<DropdownToggle caret color="info" transform="shrink-3" className={buttonDefaultClasses}>
						<FontAwesomeIcon icon="calendar" />
						<span className="d-none d-md-inline-block ml-2">{local.TS_Range}</span>
					</DropdownToggle>
					<DropdownMenu className="menu-border-blue dropdown-menu">
						<DropdownItem className={period === "Day" ? "active" : ""} onClick={() => updatePeriod("Day")}>
							{local.TS_Day}
						</DropdownItem>
						<DropdownItem className={period === "Week" ? "active" : ""} onClick={() => updatePeriod("Week")}>
							{local.TS_Week}
						</DropdownItem>
						<DropdownItem className={period === "Month" ? "active" : ""} onClick={() => updatePeriod("Month")}>
							{local.TS_Month}
						</DropdownItem>
						<DropdownItem className={period === "Year" ? "active" : ""} onClick={() => updatePeriod("Year")}>
							{local.TS_Year}
						</DropdownItem>
						{!emailReports && (
							<DropdownItem className={period === "Custom" ? "active" : ""} onClick={() => setShowPeriod(true)}>
								{local.TF_Custom}
							</DropdownItem>
						)}
					</DropdownMenu>
				</UncontrolledDropdown>

				{/* Used to add an overlay to the charts */}

				{isFeatureEnabled(features.ChartReportOverlays) && (
					<Button className={buttonDefaultClasses} color="success" transform="shrink-3" onClick={toggleShowOverlay}>
						<FontAwesomeIcon icon="plus" />
						<span className="d-none d-md-inline-block ml-2">{local.TF_Overlays}</span>
					</Button>
				)}

				{/* Used to add a default header & footer on all pages */}
				<Button className={buttonDefaultClasses} color="warning" transform="shrink-3" onClick={toggleShowHeaderFooter}>
					<FontAwesomeIcon icon="align-justify" />
					<span className="d-none d-md-inline-block ml-2">{local.TF_Header_and_Footer}</span>
				</Button>

				{/* Used to select number of charts on page and how the charts are laid out */}
				<UncontrolledDropdown size="sm">
					<DropdownToggle caret color="primary" transform="shrink-3" className={buttonDefaultClasses}>
						<FontAwesomeIcon icon="object-group" />
						<span className="d-none d-md-inline-block ml-2">{local.TF_Layout}</span>
					</DropdownToggle>
					<DropdownMenu className="menu-border-blue dropdown-menu">
						<DropdownItem header>{local.TF_Charts_Per_Page}</DropdownItem>
						<DropdownItem className={chartsPerPage === 1 ? "active" : ""} onClick={() => updateChartsPerPage(1)}>
							1
						</DropdownItem>
						<DropdownItem className={chartsPerPage === 2 ? "active" : ""} onClick={() => updateChartsPerPage(2)}>
							2
						</DropdownItem>
						<DropdownItem className={chartsPerPage === 3 ? "active" : ""} onClick={() => updateChartsPerPage(3)}>
							3
						</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown>

				{/* Used to set page to horizontal or vertical */}
				<Button className={buttonDefaultClasses} color="danger" transform="shrink-3" onClick={toggleLandscape}>
					{landscape ? (
						<>
							<FontAwesomeIcon icon="arrows-h" />
							<span className="d-none d-md-inline-block ml-2">{local.TF_Landscape}</span>
						</>
					) : (
						<>
							<FontAwesomeIcon icon="arrows-v" />
							<span className="d-none d-md-inline-block ml-2">{local.TF_Portrait}</span>
						</>
					)}
				</Button>
			</Row>
			<>
				<SortableContainer onSortEnd={onSortEnd}>{orderList && orderList.map((item, index) => <SortableItem key={`item-${index}`} index={index} value={item} />)}</SortableContainer>
			</>
		</>
	);
};

export default ChartReportToolbar;
