import { library } from "@fortawesome/fontawesome-svg-core";
import { faAlignLeft, faAlignRight, faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faArrowDown, faArrowsAltV, faBan, faBell, faBook, faCamera, faCaretRight, faChartArea, faChartLine, faChartPie, faCheck, faCheckCircle, faChevronLeft, faChevronRight, faChevronUp, faCircle, faComment, faCommentSlash, faCopy, faCrown, faDollarSign, faEllipsisH, faExchangeAlt, faExclamationCircle, faExclamationTriangle, faExternalLinkAlt, faFileUpload, faFilter, faHeart, faHome, faKey, faLink, faListAlt, faLock, faMicrophone, faMicrophoneSlash, faMinus, faPencilAlt, faPlay, faPlayCircle, faPlug, faPlus, faPrint, faPuzzlePiece, faQuestionCircle, faRulerCombined, faSave, faSearch, faShareAlt, faSignInAlt, faSort, faSortAlphaDown, faSortAlphaUp, faSortNumericDown, faSortNumericUp, faStopCircle, faStream, faTable, faTimes, faTimesCircle, faTrash, faUser, faUserCircle } from "@fortawesome/free-solid-svg-icons";

import { faAlarmClock, faBarcodeAlt, faBars, faBatteryThreeQuarters, faChartScatter, faDownload, faEnvelopeSquare, faHourglassHalf, faLightbulb, faLightbulbSlash, faRouter, faSatelliteDish, faSpinner, faThumbtack, faUpload } from "@fortawesome/pro-solid-svg-icons";

import { faFacebook, faFacebookF, faFacebookSquare, faGooglePlusG, faGulp, faHotjar, faLinkedinIn, faMediumM, faNodeJs, faSass, faTwitter } from "@fortawesome/free-brands-svg-icons";

import { faObjectUngroup, faPaperPlane } from "@fortawesome/free-regular-svg-icons";

library.add(
	// Solid
	faChartPie,
	faCopy,
	faSearch,
	faHome,
	faBell,
	faPlay,
	faChevronRight,
	faChevronLeft,
	faChevronUp,
	faExchangeAlt,
	faAngleLeft,
	faAngleDoubleLeft,
	faAngleRight,
	faAngleDoubleRight,
	faEllipsisH,
	faCrown,
	faListAlt,
	faCheck,
	faBan,
	faStream,
	faArrowsAltV,
	faSort,
	faSortAlphaDown,
	faSortAlphaUp,
	faSortNumericDown,
	faSortNumericUp,
	faCheckCircle,
	faUserCircle,
	faQuestionCircle,
	faLock,
	faCircle,
	faHeart,
	faShareAlt,
	faCaretRight,
	faArrowDown,
	faPrint,
	faDollarSign,
	faLink,
	faPlus,
	faMinus,
	faUser,
	faPencilAlt,
	faCamera,
	faTimesCircle,
	faSignInAlt,
	faBook,
	faPuzzlePiece,
	faTrash,
	faAlignLeft,
	faAlignRight,
	faPlug,
	faTimes,
	faFilter,
	faExternalLinkAlt,
	faChartLine,
	faTable,
	faRulerCombined,
	faAlarmClock,
	faChartArea,
	faDownload,
	faUpload,
	faRouter,
	faExclamationTriangle,
	faExclamationCircle,

	// Brand
	faFacebook,
	faFacebookSquare,
	faGulp,
	faNodeJs,
	faSass,
	faFacebookF,
	faTwitter,
	faGooglePlusG,
	faLinkedinIn,
	faMediumM,
	faHotjar,

	// Regular
	faObjectUngroup,
	faPaperPlane,
	faEnvelopeSquare,
	faThumbtack,
	faSatelliteDish,
	faBatteryThreeQuarters,
	faBarcodeAlt,
	faBars,
	faKey,
	faFileUpload,
	faSave,
	faSpinner,
	faMicrophone,
	faMicrophoneSlash,
	faLightbulb,
	faLightbulbSlash,
	faHourglassHalf,
	faStopCircle,

	faComment,
	faCommentSlash,
	faPlayCircle,
	faChartScatter,
);
