import React from "react";
import { Button, Card, CardDeck, Row } from "reactstrap";
import local from "../../localization/strings";
import SiteThumbnail from "./SiteThumbnail";

const UserSiteImages = ({ sites, accessSite, when }) => {
	return (
		<div className="fs--1 font-weight-normal p-3 w-100">
			{sites && (
				<CardDeck className="bg-transparent">
					{sites.map((site, i) => (
						<Card key={`${i}_${when}`} index={i} className="dash-border col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 py-3 px-4 mb-2 distributor-card">
							<Row className="distributor-site-image">
								<SiteThumbnail displayName={site.displayName} id={site.id} when={when} />
							</Row>
							<Row className="mt-2 distributor-site-name">
								<h5 className="mx-auto text-center">{site.displayName}</h5>
							</Row>
							<Row>
								{/* Button click to change currently selected site & navigate to dashboard */}
								<Button color="primary" className="mx-auto mt-2 mb-0" onClick={() => accessSite(site.id)}>
									{local.TF_View_site}
								</Button>
							</Row>
						</Card>
					))}
				</CardDeck>
			)}
		</div>
	);
};

export default UserSiteImages;
